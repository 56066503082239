export const pageData = {
  title: 'Content Development',
  breadcrumbs: [
    { text: 'SEO', url: '/seo/' },
    { text: 'Social', url: '/seo/social/' },
    { text: 'PPC', url: '/seo/social/ppc/' },
    { text: 'Blogging', url: '/seo/social/ppc/blogging/' },
    { text: 'Content', url: '/seo/social/ppc/blogging/content/' },
    { text: 'Custom', url: '/seo/social/ppc/blogging/content/custom/' },
  ],
  uri: '/real-estate-internet-marketing/content-development/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  layout: { container: 'product-intro-bg' },
  heading: {
    text: 'In-Depth Content. Undeniable Expertise.',
    className: 'heading-1',
  },
  subheading: {
    text: 'LONG-FORM CONTENT THAT BOOSTS YOUR CREDIBILITY',
    className: 'subtitle-7',
  },
  paragraph: {
    text:
      'True Content gives your audience the deep-dive answers they need, all while taking you to the top<br/> of search rankings.',
    className: 'default-body',
  },
}

export const tabsData = {
  activeTab: 4,
  tabsClassName: 'small-heading',
  heading: 'Be The Go-To Real Estate Expert<br/> With True Content',
  headingMobile: 'Be The Go-To<br/> Real Estate Expert<br/> With True Content',
  headingClassName: 'heading-5',
  headingDesc: [
    'Real estate clients have a lot of questions. And who better to give them the information and insight they need than you?',
    'True Content, our premium content development service, allows you to showcase your mastery of the market like never before.',
    'Our in-depth writing gives your prospects the full picture so you can gain their full trust. Even better, high-quality long-form content establishes your authority so you can climb the search engine rankings.',
    'All of this is done for you by our talented team of professional real estate writers. From buying/selling guides, to<br/> property market deep-dives, to thought leadership pieces, we can make it happen.',
  ],
  headingDescClassName: 'subtitle-5',
  benefitsHeader: 'BEST FOR',
  benefitsHeaderClassName: 'subtitle-4',
  benefitsSubheader:
    'Agents who want to consistently attract organic traffic from high-intent leads — the kind of prospects who are already doing extensive research into buying or selling a property.',
  benefitsSubheaderClassName: 'subtitle-5',
  benefitsListHeader: 'Benefits of<br/> True Content',
  benefitsListHeaderClassName: 'heading-3',
  benefitsDescList: [
    'Improve Online Visibility',
    'Establish Credibility',
    'Attrack More Website Traffic',
    'Target Niche Audiences',
    'Generate More Leads & Conversions',
    'Gain Market Insight With Analytics',
  ],
  benefitsDescClassName: 'subtitle-5',
  overviewHeader: 'Overview of True Content',
  overviewHeaderClassName: 'heading-5',
  overviewListHeader: 'Package Inclusions',
  overviewListHeaderClassName: 'subtitle-4',
  overviewListSubheader: 'True Content Project Duration: ',
  overviewListSubheaderClassName: 'subtitle-5',
  overviewListSubheaderDuration: '<b>6 months</b>',
  overviewListSubheaderDurationClassName: '',
  overviewDescList: [
    '1 long-form content per month<br/> (2,500 words)',
    'Competitor & keyword search',
    'Topic proposal & content outline',
    'Content writing (includes target keywords)',
    'Off-page search engine<br/> optimization (SEO)',
    'Content ranking & performance<br/> report',
  ],
  overviewDescClassName: 'subtitle-5',
}

export const diffData = {
  heading: 'How Is True Content Different from Blogging?',
  headingClassName: 'heading-3',
  desc: [
    'Every real estate client is on a consumer journey.',
    'The earlier they are in that journey, the more broad their questions are. Shorter content like blogs give them just enough information so they<br/> can determine their next step.',
    'On the flip side, the deeper into that journey a person is, the more<br/> detailed the information they need: job market data, good school<br/> districts, comparable property prices, local lifestyle, nearby attractions, market trends, etc.',
    'True Content targets these highly motivated prospects and positions<br/> you as the expert who has all the answers.',
  ],
  descClassName: 'default-body large',
}

export const servicesData = {
  heading: 'Not ready for a True Content package yet?',
  headingClassName: 'heading-3',
  subheading: [
    'If True Content is too big a commitment for you at the moment, we provide professional blogging services as well. Even better, we have both recurring and a la carte plans to give you greater flexibility. ',
    'We take the same care in writing and optimizing our blogs, so you have a strong starting point when you’re ready to try long-form content development.',
  ],
  subheadingClassName: 'subtitle-5',
}
