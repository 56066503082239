import React, { useState } from 'react'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import useMedia, { media } from '@hooks/useMedia'

import * as cx from './TabContent.module.scss'

const Tabs = ({
  bgImage,
  img1,
  img2,
  img2Mobile,
  buttonClick,
  content,
  heading,
  headingMobile,
  headingClassName,
  headingDesc,
  headingDescClassName,
  benefitsHeader,
  benefitsHeaderClassName,
  benefitsSubheader,
  benefitsSubheaderClassName,
  benefitsListHeader,
  benefitsListHeaderClassName,
  benefitsDescClassName,
  overviewHeader,
  overviewHeaderClassName,
  overviewListHeader,
  overviewListHeaderClassName,
  overviewListSubheader,
  overviewListSubheaderClassName,
  overviewListSubheaderDuration,
  overviewListSubheaderDurationClassName,
  overviewDescClassName,
}) => {
  const tablet = useMedia(media.tablet)
  const mobile = useMedia(media.phone)
  const [image, setImage] = useState(img2)
  const [header, setHeader] = useState(heading)

  React.useEffect(() => {
    if (tablet || mobile) {
      if (tablet) {
        setHeader(heading)
      } else if (mobile) {
        setHeader(headingMobile)
      }
      setImage(img2Mobile)
    } else {
      setHeader(heading)
      setImage(img2)
    }
  }, [tablet, mobile])

  return (
    <section
      id="tabs-content"
      className={cx.wrapper}
      style={{ backgroundImage: `url(${bgImage ? bgImage : 'none'})` }}
    >
      <div className={`${cx.container} container`}>
        <div className={cx.contentContainer}>
          <h1 className={headingClassName}>{parse(header)}</h1>
          <hr />
          <div className={cx.descContainer}>
            {headingDesc.map((item, index) => {
              return (
                <p key={index} className={headingDescClassName}>
                  {parse(item)}
                </p>
              )
            })}
          </div>

          <div className={cx.benefitsWrapper}>
            <div className={cx.benefitsContentContainer}>
              <div className={cx.benefitsHeadingContainer}>
                <h3 className={benefitsHeaderClassName}>{benefitsHeader}</h3>
                <p className={benefitsSubheaderClassName}>
                  {benefitsSubheader}
                </p>
              </div>

              <div className={cx.benefitsListContainer}>
                <h1 className={benefitsListHeaderClassName}>
                  {parse(benefitsListHeader)}
                </h1>
                <div>
                  {content.benefitsDescList?.map((item, index) => {
                    return (
                      <div key={index} className={cx.benefitsDesc}>
                        <div>
                          <SvgCheckedCircle className={cx.checkSvg} />
                        </div>
                        <div>
                          <p className={benefitsDescClassName}>{item}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className={cx.benefitsImgContainer}>
              <ElementImage src={img1} alt={'Benefits Image'} />
            </div>
          </div>

          <hr />

          <div className={cx.overviewWrapper}>
            <h1 className={overviewHeaderClassName}>{overviewHeader}</h1>
            <div className={cx.overviewContainer}>
              <div className={cx.overviewImgContainer}>
                <ElementImage
                  src={image}
                  alt={'Benefits Image'}
                  className={cx.overviewImage}
                />
              </div>

              <div className={cx.overviewListContainer}>
                <div className={cx.overviewListHeaderContainer}>
                  <h3 className={overviewListHeaderClassName}>
                    {overviewListHeader}
                  </h3>
                  <p className={overviewListSubheaderClassName}>
                    {overviewListSubheader}
                    {parse(overviewListSubheaderDuration)}
                  </p>
                </div>
                <div className={cx.overviewDescList}>
                  {content.overviewDescList?.map((item, index) => {
                    return (
                      <div key={index} className={cx.overviewDesc}>
                        <div>
                          <SvgCheckedCircle className={cx.checkSvg} />
                        </div>
                        <div>
                          <p className={overviewDescClassName}>{parse(item)}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <button
                  className={clsx(cx.button, 'primary-button')}
                  onClick={buttonClick}
                >
                  Get Inclusions & Pricing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tabs
