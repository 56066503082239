import { useStaticQuery, graphql } from 'gatsby'

const useContentDevelopmentMetadata = () => {
  const query = useStaticQuery(graphql`
    query contentDevelopmentMetadata {
      bannerBG: file(relativePath: { eq: "content-development/bannerBG.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      bannerImg: file(
        relativePath: { eq: "content-development/bannerImage.png" }
      ) {
        id
        ...ContentDevelopmentImageOptimize
      }
      # bannerImgT: file(
      #   relativePath: { eq: "content-development/bannerImageTablet.png" }
      # ) {
      #   id
      #   ...ContentDevelopmentImageOptimize
      # }
      # bannerImgM: file(
      #   relativePath: { eq: "content-development/bannerImageMobile.png" }
      # ) {
      #   id
      #   ...ContentDevelopmentImageOptimize
      # }
      tabsBG: file(relativePath: { eq: "content-development/tabsBG.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      tabsImg1: file(relativePath: { eq: "content-development/tabsImg1.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      tabsImg2: file(relativePath: { eq: "content-development/tabsImg2.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      tabsImg2M: file(
        relativePath: { eq: "content-development/tabsImg2M.png" }
      ) {
        id
        ...ContentDevelopmentImageOptimize
      }
      diffImg: file(relativePath: { eq: "content-development/diff.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      diffImgT: file(relativePath: { eq: "content-development/diff-t.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      diffImgM: file(relativePath: { eq: "content-development/diff-m.png" }) {
        id
        ...ContentDevelopmentImageOptimize
      }
      servicesImg: file(
        relativePath: { eq: "content-development/services.png" }
      ) {
        id
        ...ContentDevelopmentImageOptimize
      }
    }

    fragment ContentDevelopmentImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useContentDevelopmentMetadata
