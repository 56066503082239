import React from 'react'
import parse from 'html-react-parser'
import ElementImage from '@components/global/element/Image'

import * as cx from './Services.module.scss'

/* MAX-W-1280 7REM LEFT P/M of Image */

const Services = ({
  headingClassName,
  subheadingClassName,
  content,
  image,
  buttonClick,
}) => {
  return (
    <section id="services" className={cx.wrapper}>
      <div className={`${cx.container} container`}>
        <div className={cx.item}>
          <div className={cx.imageContainer}>
            <ElementImage
              src={image}
              alt={'Blog Content Image'}
              className={cx.image}
            />
          </div>
          <div className={cx.contentContainer}>
            <h1 className={headingClassName}>{content.heading}</h1>
            {content.subheading?.map((item, index) => {
              return (
                <h3 key={index} className={subheadingClassName}>
                  {parse(item)}
                </h3>
              )
            })}
            <button
              className={`${cx.button} primary-button large`}
              onClick={buttonClick}
            >
              {`see our blogging packages`}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
