import React, { useState } from 'react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'

import * as cx from './Difference.module.scss'

const Difference = ({
  imgDesktop,
  imgTablet,
  imgMobile,
  heading,
  headingClassName,
  desc,
  descClassName,
  buttonClick,
}) => {
  const tablet = useMedia(media.tablet)
  const mobile = useMedia(media.phone)
  const [image, setImage] = useState(imgDesktop)

  React.useEffect(() => {
    if (mobile) {
      setImage(imgMobile)
    } else if (tablet) {
      setImage(imgTablet)
    } else {
      setImage(imgDesktop)
    }
  }, [tablet, mobile])

  return (
    <section id="difference" className={cx.wrapper}>
      <div className={`${cx.container} container`}>
        <div className={cx.item}>
          <div className={cx.contentContainer}>
            <div className={cx.contentWrapper}>
              <h1 className={headingClassName}>{parse(heading)}</h1>
              <div className={cx.descList}>
                {desc?.map((item, index) => {
                  return (
                    <div key={index} className={cx.desc}>
                      <p className={descClassName}>{parse(item)}</p>
                    </div>
                  )
                })}
              </div>
              <button
                className={`${cx.button} primary-button large`}
                onClick={buttonClick}
              >
                want to know more?
              </button>
            </div>
          </div>
          <div className={cx.imageContainer}>
            <ElementImage src={image} alt={'Blog Content Image'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Difference
