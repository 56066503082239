import React from 'react'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import TabContent from '@components/pages/content-development/TabContent'
import Diffs from '@components/pages/content-development/Difference'
import Services from '@components/pages/content-development/Services'

import Seo from '@components/seo/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import {
  pageData as page,
  bannerData,
  tabsData,
  diffData,
  servicesData,
} from '@src/data/ContentDevelopment'
import useContentDevelopmentMetadata from '@hooks/content-development-metadata'

import * as cx from './ContentDevelopment.module.scss'

const ContentDevelopment = () => {
  const {
    bannerBG,
    bannerImg,
    bannerImgT,
    bannerImgM,
    tabsBG,
    tabsImg1,
    tabsImg2,
    tabsImg2M,
    diffImg,
    diffImgT,
    diffImgM,
    servicesImg,
  } = useContentDevelopmentMetadata()
  const modalFunc = React.useRef()

  return (
    <LayoutInnerPage buttonsHeight={page.buttonsHeight}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout={bannerData.layout.container}
        // wrapperClassName={cx.bannerWrapper}
        bgImage={extractImage(bannerBG)}
        breadcrumbs={page.breadcrumbs}
        bannerImage={extractImage(bannerImg)}
        bannerImageClassName={cx.bannerImg}
        title={bannerData.heading.text}
        titleClassName={bannerData.heading.className}
        subtitle2={bannerData.subheading.text}
        subtitle2ClassName={bannerData.subheading.className}
        description={bannerData.paragraph.text}
        descriptionClassName={bannerData.paragraph.className}
        button_click={(e) => modalFunc.current.openModal()}
        button_text="Learn more about true content"
        button_link="/"
      />
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today For A<br/> Free Consultation"
        mobileTitle="Sign Up Today For A<br/> Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <Tabs
        tabsClassName={tabsData.tabsClassName}
        activeTab={tabsData.activeTab}
      />
      <TabContent
        bgImage={extractImage(tabsBG)}
        img1={extractImage(tabsImg1)}
        img2={extractImage(tabsImg2)}
        img2Mobile={extractImage(tabsImg2M)}
        buttonClick={(e) => modalFunc.current.openModal()}
        content={tabsData}
        heading={tabsData.heading}
        headingMobile={tabsData.headingMobile}
        headingClassName={tabsData.headingClassName}
        headingDesc={tabsData.headingDesc}
        headingDescClassName={tabsData.headingDescClassName}
        benefitsHeader={tabsData.benefitsHeader}
        benefitsHeaderClassName={tabsData.benefitsHeaderClassName}
        benefitsSubheader={tabsData.benefitsSubheader}
        benefitsSubheaderClassName={tabsData.benefitsSubheaderClassName}
        benefitsListHeader={tabsData.benefitsListHeader}
        benefitsListHeaderClassName={tabsData.benefitsListHeaderClassName}
        benefitsDescClassName={tabsData.benefitsDescClassName}
        overviewHeader={tabsData.overviewHeader}
        overviewHeaderClassName={tabsData.overviewHeaderClassName}
        overviewListHeader={tabsData.overviewListHeader}
        overviewListHeaderClassName={tabsData.overviewListHeaderClassName}
        overviewListSubheader={tabsData.overviewListSubheader}
        overviewListSubheaderClassName={tabsData.overviewListSubheaderClassName}
        overviewListSubheaderDuration={tabsData.overviewListSubheaderDuration}
        overviewListSubheaderDurationClassName={
          tabsData.overviewListSubheaderDurationClassName
        }
        overviewDescClassName={tabsData.overviewDescClassName}
      />
      <Diffs
        imgDesktop={extractImage(diffImg)}
        imgTablet={extractImage(diffImgT)}
        imgMobile={extractImage(diffImgM)}
        heading={diffData.heading}
        headingClassName={diffData.headingClassName}
        desc={diffData.desc}
        descClassName={diffData.descClassName}
        buttonClick={(e) => modalFunc.current.openModal()}
      />
      <Services
        content={servicesData}
        headingClassName={servicesData.headingClassName}
        subheadingClassName={servicesData.subheadingClassName}
        image={extractImage(servicesImg)}
        buttonClick={(e) => modalFunc.current.openModal()}
      />
      <LeadContactForm
        version="LCF08"
        modelOrBackground="CutOutCassie"
        title="Want To Grow Your Business With Content Marketing?"
        subtitle="Our Web Marketing Strategists can tell you how. Book a FREE consultation today!"
        submitLabel="Book My Schedule"
        leadName="Lead Contact Form"
        layout="B"
      />
    </LayoutInnerPage>
  )
}

export default ContentDevelopment
