// extracted by mini-css-extract-plugin
export var benefitsContentContainer = "TabContent-module--benefits-content-container--13b6f";
export var benefitsDesc = "TabContent-module--benefits-desc--37473";
export var benefitsHeadingContainer = "TabContent-module--benefits-heading-container--6afc8";
export var benefitsImgContainer = "TabContent-module--benefits-img-container--9ed90";
export var benefitsListContainer = "TabContent-module--benefits-list-container--eec70";
export var benefitsWrapper = "TabContent-module--benefits-wrapper--9f502";
export var button = "TabContent-module--button--0e8c5";
export var checkSvg = "TabContent-module--check-svg--9b6d7";
export var container = "TabContent-module--container--8b165";
export var contentContainer = "TabContent-module--content-container--06ad0";
export var descContainer = "TabContent-module--desc-container--0b7fb";
export var overviewContainer = "TabContent-module--overview-container--2a416";
export var overviewDesc = "TabContent-module--overview-desc--d16c3";
export var overviewDescList = "TabContent-module--overview-desc-list--ef279";
export var overviewImage = "TabContent-module--overview-image--c703e";
export var overviewImgContainer = "TabContent-module--overview-img-container--3af1a";
export var overviewListContainer = "TabContent-module--overview-list-container--f0ac5";
export var overviewListHeaderContainer = "TabContent-module--overview-list-header-container--ed876";
export var overviewWrapper = "TabContent-module--overview-wrapper--8a0ae";
export var wrapper = "TabContent-module--wrapper--b5bc1";